<script lang="ts" setup>
import GuestLayout from '@/Layouts/GuestLayout.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';
import { useGlobalPropsUnauthenticated } from '@/Compositions/useGlobalProps';
import BaseButton from '@/Components/Base/BaseButton.vue';
import BaseFormField from '@/Components/Base/BaseFormField.vue';
import BaseInput from '@/Components/Base/BaseInput.vue';
import OAuthGoogleButton from '@/Pages/Auth/Components/OAuthGoogleButton.vue';
import { ShowLoginPageProps } from '@/generated';
import SameEmailAlert from '@/Pages/Auth/Components/SameEmailAlert.vue';

const { isLocalEnvironment } = useGlobalPropsUnauthenticated();

const props = defineProps<ShowLoginPageProps>();

const form = useForm({
  invite_id: props.invite?.id ?? null,
  email: props.invite?.email ?? '',
  password: '',
});

const submit = () => {
  form.post(route('login.store'), {
    onFinish: () => form.reset('password'),
  });
};

function login(email: string) {
  form.email = email;
  form.password = 'password';
  submit();
}
</script>

<template>
  <GuestLayout title="Sign in to your account">
    <Head title="Log in" />

    <form @submit.prevent="submit">
      <div class="flex flex-col space-y-6">
        <BaseFormField title="Email">
          <BaseInput
            v-model="form.email"
            type="email"
            autofocus
            :error="form.errors.email"
            :disabled="props.invite !== null"
            @keydown.enter="submit"
          />
        </BaseFormField>

        <BaseFormField title="Password">
          <BaseInput
            v-model="form.password"
            type="password"
            autocomplete="current-password"
            :error="form.errors.password"
            @keydown.enter="submit"
          />
        </BaseFormField>
      </div>

      <div class="flex items-center space-x-2 mt-6">
        <Link
          :href="route('forgot-password.show')"
          class="flex-shrink-0"
        >
          <BaseButton
            type="tertiary"
            label="Forgot your password?"
            ghost
            class="w-full"
          />
        </Link>
        <div class="flex-1">
          <BaseButton
            label="Log in"
            class="w-full"
            @click="submit"
          />
        </div>
      </div>
      <div class="relative my-8">
        <div
          class="absolute inset-0 flex items-center"
          aria-hidden="true"
        >
          <div class="w-full border-t border-gray-200" />
        </div>
        <div class="relative flex justify-center text-sm font-medium leading-6">
          <span class="bg-white px-6 text-gray-900">Or continue with</span>
        </div>
      </div>
      <div class="flex flex-col items-center space-y-4 mt-6">
        <SameEmailAlert v-if="invite !== null" />
        <OAuthGoogleButton />
        <div
          v-if="isLocalEnvironment"
          class="flex space-x-2 w-full"
        >
          <BaseButton
            class="flex-1"
            type="tertiary"
            label="Alex"
            @click="login('workhighlights.app+publisher@gmail.com')"
          />
          <BaseButton
            class="flex-1"
            type="tertiary"
            label="Megan"
            @click="login('workhighlights.app+marketer@gmail.com')"
          />
          <BaseButton
            class="flex-1"
            type="tertiary"
            label="Elon"
            @click="login('workhighlights.app+admin@gmail.com')"
          />
        </div>

        <div
          v-if="invite === null"
          class="flex"
        >
          <p class="mt-2 text-sm leading-6 text-gray-500">
            Not a member?
            <Link
              :href="route('register.show')"
              class="font-semibold text-blue-600 hover:text-blue-500"
            >
              Create an account.
            </Link>
          </p>
        </div>
      </div>
    </form>
  </GuestLayout>
</template>
